/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs'
import { ArrowCircleUp, ArrowCircleDown } from 'iconsax-react'
import { formatNumber } from 'helpers/formatBalance'
import { useAppSelector } from 'store'

import styles from './Transaction.styles'

const TransactionHistory = () => {
  const transaction = useAppSelector((state) => state.transaction)

  if (transaction?.loading) {
    return <div>Loading...</div>
  }
  if (transaction?.data?.length === 0) {
    return <div css={styles.noTransaction}>No transaction</div>
  }

  return (
    <div css={styles.wrapper}>
      {transaction?.data &&
        transaction?.data?.length > 0 &&
        transaction?.data.map((item) => {
          if (item.status !== 'PENDING') {
            return (
              <div css={styles.transactionItem} key={item?.txId}>
                <div css={styles.transactionDate}>
                  {item?.createdTime
                    ? dayjs(new Date(item?.createdTime)).format(
                        'MMMM DD [at] hh:mma',
                      )
                    : ''}
                </div>
                <div css={[styles.flex, styles.transactionRow]}>
                  <div css={[styles.flex]}>
                    <div css={[styles.transactionDirectionIcon]}>
                      {item?.direction === 'OUT' ? (
                        <ArrowCircleUp size='24' color='#D0E0F7' />
                      ) : (
                        <ArrowCircleDown size='24' color='#D0E0F7' />
                      )}
                    </div>
                    <div css={styles.transactionDirection}>
                      <div>
                        {item?.direction === 'OUT'
                          ? 'To External'
                          : 'To Spending'}
                      </div>
                      <div
                        css={[
                          styles.transactionStatus,
                          item.status === 'SUCCESS'
                            ? styles.transactionSuccess
                            : styles.transactionFail,
                        ]}
                      >
                        {item.status}
                      </div>
                    </div>
                  </div>

                  <div css={styles.transactionTotal}>
                    {item?.direction === 'OUT' ? '-' : '+'}
                    {formatNumber(item?.amount || 0)} {item?.tokenType}
                  </div>
                </div>
              </div>
            )
          }

          return null
        })}
    </div>
  )
}

export default TransactionHistory
