/** @jsxImportSource @emotion/react */
import {
  Dialog,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { Global } from '@emotion/react'
import { ITokenTrade } from 'types/trade'
import styles from './SelectTokenModal.styles'

interface IProps {
  tokenList: ITokenTrade[] | undefined
  isOpen: boolean
  setShowModal: (value: boolean) => void
  onSelectToken: (token: any) => void
}

const SelectTokenModal = ({
  tokenList,
  isOpen,
  setShowModal,
  onSelectToken,
}: IProps) => {
  return (
    <>
      <Global styles={styles.dialog} />
      <Dialog
        open={isOpen}
        onClose={() => setShowModal(false)}
        classes={{ paper: 'select-token-paper' }}
      >
        <Box css={styles.wrapper}>
          <Typography variant='subtitle2' component='div' css={styles.title}>
            Select Asset
          </Typography>
          <List sx={{ pt: 0 }}>
            {tokenList &&
              tokenList.map((token: ITokenTrade) => (
                <ListItem
                  button
                  onClick={() => onSelectToken(token)}
                  key={token.type}
                  css={styles.listItem}
                >
                  <img src={token.icon} alt={token.name} css={styles.icon} />
                  <ListItemText primary={token.name} />
                </ListItem>
              ))}
          </List>
        </Box>
      </Dialog>
    </>
  )
}

export default SelectTokenModal
