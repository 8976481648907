import { Request } from 'helpers/request'
import { ILoginRequest, ILoginResponse, IUserInfo } from 'types/auth'
const serverBaseUrl = process.env.REACT_APP_BASE_AUTH_API

export const login = (payload: ILoginRequest) => {
  return Request.call<ILoginResponse>({
    url: '/identity.login',
    serverBaseUrl: serverBaseUrl,
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const sendLoginOTPApi = (payload: { email: string }) => {
  return Request.call<{ authToken: string }>({
    url: '/identity.send_otp',
    method: 'POST',
    data: {
      ...payload,
      type: 'LOGIN',
    },
    serverBaseUrl: serverBaseUrl,
  })
}

export const getMeApi = () => {
  return Request.call<IUserInfo>({
    url: '/identity.me',
    serverBaseUrl: serverBaseUrl,
    method: 'POST',
  })
}
