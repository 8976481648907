import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { v4 as uuidv4 } from 'uuid'

dayjs.extend(utc)

const generateSignature = (params: {
  url: string
  body: any
  method?: string
  sharedSecret: string
  contentType?: string
}) => {
  const { url, body, method = 'POST', sharedSecret, contentType } = params
  const JSON_CONTENT_TYPE = contentType ?? 'application/json'
  const timestamp = dayjs.utc().valueOf()
  const nonce = uuidv4()
  const hashBody = CryptoJS.MD5(JSON.stringify(body)).toString(CryptoJS.enc.Hex)
  const stringToSign = `${nonce}\n${timestamp}\n${method}\n${hashBody}\n${JSON_CONTENT_TYPE}\n${url}`
  const signature = CryptoJS.HmacSHA256(stringToSign, sharedSecret).toString(
    CryptoJS.enc.Hex,
  )

  return `nonce="${nonce}",timestamp="${timestamp}",signature="${signature}"`
}

export { generateSignature }
