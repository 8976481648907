/** @jsxImportSource @emotion/react */
import { Modal } from '@mui/material'
import { WITHDRAW_STEPS, IWithdrawFee } from 'types/wallet'
import ConfirmInformation from './ConfirmInformation'
import VerifyWithdrawOTP from './VerifyWithdrawOTP'

export interface IWithdrawProps {
  otp?: string
  step?: string
  fee: IWithdrawFee
  token: any
  amount: string | undefined
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  setStep: (value: string) => void
  setOtp: (value: string) => void
}

const WithdrawConfirmModal = (props: IWithdrawProps) => {
  const { isOpen, onClose, step, setStep } = props

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setStep(WITHDRAW_STEPS.INFO)
        onClose()
      }}
    >
      <div>
        {step === WITHDRAW_STEPS.INFO && <ConfirmInformation {...props} />}
        {step === WITHDRAW_STEPS.OTP && <VerifyWithdrawOTP {...props} />}
      </div>
    </Modal>
  )
}

export default WithdrawConfirmModal
