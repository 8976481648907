/* eslint-disable no-alert */
import { useCallback } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
  NoEthereumProviderError,
} from '@web3-react/injected-connector'
import { connectorsByName } from 'utils/web3React'
import { setupNetwork } from 'utils/wallet'

export const connectorLocalStorageKey = 'connectorIdv2'
export const walletLocalStorageKey = 'wallet'

const useAuth = () => {
  // const dispatch = useAppDispatch()
  const { chainId, activate, deactivate } = useWeb3React()

  const login = useCallback(
    (connectorID: string) => {
      const connector = connectorsByName[connectorID]

      if (connector) {
        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork()

            if (hasSetup) {
              activate(connector)
            }
          } else {
            window.localStorage.removeItem(connectorLocalStorageKey)
            if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
              alert('No provider was found')
            } else {
              alert('Error')

            }
          }
        })
      } else {
        alert('Unable to find connector')
      }
    },
    [activate],
  )

  const logout = useCallback(() => {
    // dispatch(profileClear())
    deactivate()
    window.localStorage.removeItem(connectorLocalStorageKey)
    window.localStorage.removeItem(walletLocalStorageKey)

    if (chainId) {
      // dispatch(clearAllTransactions({ chainId }))
    }
  }, [deactivate, chainId])

  return { login, logout }
}

export default useAuth
