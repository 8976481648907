import { css } from '@emotion/react'
import Images from 'images'

const styles = {
  wrapper: css`
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  `,
  imgBackground: css`
    padding: 30px 11px 60px 11px;
    background-image: url(${Images.MintBackground});
    background-size: cover;
    margin-bottom: 24px;
  `,
  logo: css`
    display: flex;
    justify-content: center;
    img {
      height: 40px;
    }
  `,
  leftShoe: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 150px;
    }
  `,
  rightShoe: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    img {
      height: 150px;
      transform: scaleX(-1);
    }
  `,
  rightShoeAdd: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 36px;
    cursor: pointer;
    img {
      height: 90px;
    }
  `,
  wrapShoe: css`
    display: flex;
  `,

  shoeInfo: css`
    display: flex;
    width: 120px;
    height: 24px;
    border-radius: 20px;
    overflow: hidden;
    & > div {
      flex: 1;
      background: #cfddff;
      text-align: center;
      padding: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #141414;

      &:first-child {
        margin-right: 2px;
      }
    }
  `,
  price: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.0125em;
    margin: 16px 0;

    color: #ffffff;
  `,
  priceInfo: css`
    padding: 0 16px;
    background: #25253d;
    border-radius: 12px;

    display: flex;
    align-items: center;
    & > p {
      flex: 1;

      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #bfbfbf;
    }
  `,
  buttonMint: css`
    min-width: 120px;
  `,
  actions: css`
    text-align: center;
    padding: 24px;
  `,
}

export default styles
