/** @jsxImportSource @emotion/react */

import { FormControl, InputBase } from '@mui/material'
import { ArrowDown2 } from 'iconsax-react'
import { formatNumber } from 'helpers/formatBalance'
import { ITokenTrade } from 'types/trade'
import { SWAP_DIRECTIONS } from 'types/common'
import styles from './Trading.styles'

interface IProps {
  amount: any
  token: ITokenTrade | undefined
  setAmount: (value: any) => void
  setShowModalSelectToken: (value: any) => void
  setDirection: (value: any) => void
  setAllToken: (value: any) => void
  onBlur: (value: any) => void
}
const SwapFrom = ({
  amount,
  token,
  setAmount,
  setShowModalSelectToken,
  setDirection,
  setAllToken,
  onBlur,
}: IProps) => {
  return (
    <>
      <div
        css={styles.tokenSelect}
        onClick={() => {
          setShowModalSelectToken(true)
          setDirection(SWAP_DIRECTIONS.IN)
        }}
      >
        <div css={styles.tokenIcon}>
          <img src={token?.icon} alt={token?.name} />
        </div>
        <div css={styles.tokenName}>{token?.name}</div>
        <ArrowDown2 size='24' color='#D0E0F7' className='spending' />
      </div>
      <div css={styles.formControl}>
        <FormControl fullWidth>
          <InputBase
            placeholder='0.0'
            value={amount}
            onChange={(e) => {
              setAmount(
                e.target.value
                  .replace(/[^0-9.]/g, '')
                  .replace(/(\..*?)\..*/g, '$1'),
              )
            }}
            css={styles.inputWrapper}
            onBlur={onBlur}
          />
        </FormControl>

        {token?.balance && +amount > +token?.balance ? (
          <div css={styles.validate}>
            <span>Max: {formatNumber(+token.balance, 2)}</span>
          </div>
        ) : (
          ''
        )}
      </div>
      <div css={styles.tokenMax}>
        <span>
          {token?.balance ? formatNumber(+token.balance, 2) : 0} {token?.name}{' '}
          available to trade.
        </span>
        <span css={styles.tokenAll} onClick={setAllToken}>
          All
        </span>
      </div>
    </>
  )
}

export default SwapFrom
