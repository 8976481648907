import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  modalWrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    max-height: 90vh;
    overflow: auto;
    width: 300px;
  `,
  modalTitle: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 24px;
    text-align: center;
    margin: 0 auto 20px auto;
  `,
  qrCodeAddress: css`
    text-align: center;

  `,
  scanNote: css`
    color: ${baseColors.neutralColor6};
    text-align: center;
    margin: 20px 0;
    font-size: 12px;
  `,
  shortAddress: css`
    margin: 20px 0;
    text-align: center;
  `,
  action: css`
    text-align: center;
  `,
  copyBtn: css`
    min-width: 200px;
    margin: 0 auto;
  `,

}

export default styles
