import { css } from '@emotion/react'

const styles = {
  boxWrapper: css`
    background: #25253d;
    border-radius: 8px;
  `,
  boxHeader: css`
    padding: 9px 8px;
    border-bottom: 1px solid #2c395b;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #cfddff;
  `,
  boxBody: css`
    text-align: center;
    img {
      width: 120px;
    }
  `,
  id: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 18px;
  `,
}

export default styles
