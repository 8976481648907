import { CircularProgress, Grid } from '@mui/material'
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller'
interface IProps {
  items: any[]
  fecthMoreData: any
  hasMore: boolean
}
const InfiniteLoadingList = ({ items, fecthMoreData, hasMore }: IProps) => {
  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={fecthMoreData}
      hasMore={hasMore}
      loader={
        <div className='dfc mt-3'>
          <CircularProgress />
        </div>
      }
      useWindow={false}
    >
      <Grid container spacing={2}>
        {items}
      </Grid>
    </InfiniteScroll>
  )
}

export default InfiniteLoadingList
