import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css`
    display: flex;
  `,
  pageHeader: css`
    display: flex;
    align-items: center;
  `,
  iconBack: css`
    margin-right: 10px;
  `,
  contentWrapper: css`
    padding: 16px;
    flex: 1;
  `,
  tokenIconWrapper: css`
    text-align: center;
    margin-top: 64px;
    margin-bottom: 52px;
  `,
  tokenIcon: css`
    width: 64px;
    height: 64px;
  `,
  formWrapper: css`
    margin: 50px 0 0 0;
  `,
  formControl: css`
    margin: 0 0 20px 0;
    .Mui-disabled.MuiOutlinedInput-root {
      background: #484861;
      border-radius: 12px;
    }
  `,
  formLabel: css`
    margin: 0 0 8px 0;
    color: ${baseColors.neutralColor7};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  `,
  confirmBtn: css`
    width: 100%;
    max-width: 220px;
    margin: 50px auto 0;
    padding: 10px 0;
    display: block;
    cursor: pointer;
  `,
  inputWrapper: css`
    background: #25253d;
    color: #fff;
    border: none;
    border-radius: 12px;
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }
  `,
  tokenType: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${baseColors.neutralColor1};
  `,
  all: css`
    font-weight: 600;
    font-size: 14px;
    padding-left: 22px;
    color: #fd474c;
    cursor: pointer;
  `,
  availableBalance: css`
    font-size: 14px;
    color: ${baseColors.neutralColor7};
    margin: 10px 0 0 0;
  `,
}

export default styles
