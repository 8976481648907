/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
interface IProps {
  src: string
  width?: any
  height?: any
  children?: any
  className?: string
}
const WrapperBgImg = ({
  src,
  width = '100%',
  height = '100%',
  className,
  children,
}: IProps) => {
  return (
    <div
      className={className}
      css={css`
        background-image: url(${src});
        background-size: cover;
        background-repeat: no-repeat;
        width: ${width};
        height: ${height};
      `}
    >
      {children}
    </div>
  )
}

export default WrapperBgImg
