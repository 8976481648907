/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import dayjs from 'dayjs'
import { ethers } from 'ethers'
import { Refresh, CloseCircle, ArrangeHorizontalCircle } from 'iconsax-react'
import { Button } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { useWeb3React } from '@web3-react/core'
import {
  cancelTransaction,
  fetchTransactionHistory,
  getTransactionDetail,
} from 'store/reducers/transaction'
import { showToast } from 'store/reducers/common'
import { formatNumber } from 'helpers/formatBalance'
import { useAppDispatch, useAppSelector } from 'store'
import { getDepositWithdrawTokenContract } from 'utils/contractHelpers'
import ConfirmModal from './ConfirmModal'

import styles from './Transaction.styles'

export const TRANSACTION_ACTIONS = {
  CANCEL: 'CANCEL',
  RETRY: 'RETRY',
}

const TransactionPending = () => {
  const dispatch = useAppDispatch()
  const transaction = useAppSelector((state) => state.transaction)
  const userInfo = useAppSelector((state) => state.auth.userInfo)
  const { account } = useWeb3React()
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState<any>()
  const [isOpenModalConfirm, setShowModalConfirm] = useState(false)
  const [currentTransaction, setCurrentTransaction] = useState<any>()

  const handleShowConfirm = (tx: any, act: string) => {
    setAction(act)
    setCurrentTransaction(tx)
    setShowModalConfirm(true)
  }

  const handleCloseModal = () => {
    setCurrentTransaction(null)
    setShowModalConfirm(false)
  }

  const handleCancelTransaction = () => {
    setLoading(true)
    dispatch(
      cancelTransaction({
        txId: currentTransaction?.txId || '',
      }),
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setShowModalConfirm(false)
          dispatch(
            showToast({
              title:
                'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
            }),
          )
        } else {
          setShowModalConfirm(false)
          dispatch(
            showToast({
              title: 'Error',
              message: response.message || 'Error, please try again',
            }),
          )
        }
      })
      .finally(() => {
        setLoading(false)
        dispatch(fetchTransactionHistory({ filter: [] }))
      })
  }

  const handleRetryTransaction = () => {
    const { tokenType, txId } = currentTransaction

    try {
      const receiver = account

      const userId = userInfo?.caloId

      dispatch(
        getTransactionDetail({
          txId: currentTransaction?.txId || '',
        }),
      )
        .then(unwrapResult)
        .then(async ({ response }: any) => {
          if (response?.apiStatus !== 1) {
            setShowModalConfirm(false)
            dispatch(
              showToast({
                title: response?.message?.message || 'Error, please try again',
              }),
            )
          } else {
            setLoading(true)
            const { amount, signature } = response
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const withdrawContract = getDepositWithdrawTokenContract(signer)
            // withdraw CALO

            if (tokenType === 'CALO') {
              const tx = await withdrawContract.withdrawCalo(
                receiver,
                userId,
                ethers.utils.parseEther(amount),
                txId,
                signature,
              )

              await tx.wait()
            }
            // withdraw FIT
            if (tokenType === 'FIT') {
              const tx = await withdrawContract.withdrawFitFromContract(
                receiver,
                userId,
                ethers.utils.parseEther(amount),
                txId,
                signature,
              )

              await tx.wait()
            }
            dispatch(fetchTransactionHistory({ filter: [] }))
            setLoading(false)
            setShowModalConfirm(false)
            dispatch(
              showToast({
                title:
                  'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
              }),
            )
          }
        })
        .finally(() => {
          setLoading(false)
          dispatch(fetchTransactionHistory({ filter: [] }))
        })
    } catch (error: any) {
      setShowModalConfirm(false)
      dispatch(
        showToast({
          title: error?.message?.message || 'Error, please try again',
        }),
      )
    }
  }
  const handleConfirm = () => {
    if (action === 'CANCEL') {
      handleCancelTransaction()
    }

    if (action === 'RETRY') {
      handleRetryTransaction()
    }
  }

  if (transaction?.loading) {
    return <div>Loading...</div>
  }

  const data =
    transaction?.data &&
    transaction?.data?.filter((item: any) => item.status === 'PENDING')

  if (data?.length === 0) {
    return <div css={styles.noTransaction}>No transaction</div>
  }

  return (
    <div css={styles.wrapper}>
      {data &&
        data.map((item) => (
          <div css={styles.transactionItem} key={item?.txHash}>
            <div css={styles.transactionDate}>
              {item?.createdTime
                ? dayjs(new Date(item?.createdTime)).format(
                    'MMMM DD [at] hh:mma',
                  )
                : ''}
            </div>
            <div css={[styles.flex, styles.transactionRow]}>
              <div css={[styles.flex]}>
                <div css={[styles.transactionDirectionIcon]}>
                  {item?.direction === 'OUT' ? (
                    <ArrangeHorizontalCircle size='24' color='#D0E0F7' />
                  ) : (
                    <ArrangeHorizontalCircle size='24' color='#D0E0F7' />
                  )}
                </div>
                <div css={styles.transactionDirection}>
                  <div>
                    {item?.direction === 'OUT' ? 'To External' : 'To Spending'}
                  </div>
                  <div
                    css={[styles.transactionStatus, styles.transactionPending]}
                  >
                    {item?.status} ...
                  </div>
                </div>
              </div>

              <div css={styles.transactionTotal}>
                {formatNumber(item?.amount || 0)} {item?.tokenType}
              </div>
              <div css={styles.transactionAction}>
                <Button
                  variant='text'
                  css={styles.btnAction}
                  onClick={() =>
                    handleShowConfirm(item, TRANSACTION_ACTIONS.RETRY)
                  }
                >
                  <Refresh size='16' color='#D0E0F7' />
                </Button>
                <Button
                  variant='text'
                  css={styles.btnAction}
                  onClick={() =>
                    handleShowConfirm(item, TRANSACTION_ACTIONS.CANCEL)
                  }
                >
                  <CloseCircle size='16' color='#D0E0F7' />
                </Button>
              </div>
            </div>
          </div>
        ))}

      <ConfirmModal
        isOpen={isOpenModalConfirm}
        isLoading={loading}
        action={action}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
      />
    </div>
  )
}

export default TransactionPending
