// eslint-disable-line no-unused-vars
import { InjectedConnector } from '@web3-react/injected-connector'
import { ethers } from 'ethers'
import { ConnectorNames } from 'types/connector'

const POLLING_INTERVAL = 12000
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID || '97', 10)

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

export const connectorsByName = {
  [ConnectorNames.Injected]: injected,
}

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider)

  library.pollingInterval = POLLING_INTERVAL

  return library
}

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider: any, account: string, message: string): Promise<string> => {
  if (window.BinanceChain && window.BinanceChain.bnbSign) {
    const { signature } = await window.BinanceChain.bnbSign(account, message)

    return signature
  }

  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])

    return signature
  }

  return provider.getSigner(account).signMessage(message)
}
