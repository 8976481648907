import sample from 'lodash/sample'

if (
  process.env.NODE_ENV !== 'production' &&
  (!process.env.REACT_APP_NODE_RPC_URL)
) {
  throw Error('One base RPC URL is undefined')
}

// Array of available nodes to connect to
export const nodes = [process.env.REACT_APP_NODE_RPC_URL]

const getNodeUrl = () => {
  return sample(nodes)
}

export default getNodeUrl
