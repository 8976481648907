import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css`
    /* display: flex; */
  `,
  flex: css`
    display: flex;
  `,
  tabs: css`
    .MuiButtonBase-root {
      color: #898989;
      text-transform: none;
      &.Mui-selected {
        color: #fff;
       
      }
    }
    .MuiTabs-indicator {
      background-color: #fff;
    }
  `,
  tabPanel: css`
    .MuiBox-root {
      padding: 24px 12px;
    }
    
  `,
  noTransaction: css`
    color: ${baseColors.neutralColor7};
    text-align: center;
    padding: 50px 0;
  `,
  transactionItem: css`
    border-bottom: 1px solid #2C395B;
    padding-bottom: 20px;
    margin-bottom: 20px;
  `,
  transactionRow: css`
    justify-content: space-between;
    margin-top: 10px;
  `,
  transactionDirection: css`
    padding-left: 10px;
  `,
  transactionDirectionIcon: css`
    display: flex;
    align-items: center;
  `,
  transactionStatus: css`
    margin-top: 8px;
    font-size: 12px;
    text-transform: capitalize;
  `,
  transactionSuccess: css`
    color: ${baseColors.successColor4};
  `,
  transactionPending: css`
    color: ${baseColors.warningColor2};
  `,
  transactionFail: css`
    color: ${baseColors.dangerColor};
  `,
  transactionDate: css`
    color: ${baseColors.neutralColor6};
    font-size: 12px;
  `,
  transactionTotal: css`
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
  `,
  transactionAction: css`
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  btnAction: css`
    &.MuiButtonBase-root {
      padding: 2px;
      min-width: auto;
    }
  `,
}

export default styles
