/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from 'routes'
import { useAppDispatch } from 'store'
import { setUserToken } from 'store/reducers/auth'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const RedirectLogin = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const query = useQuery()

  useEffect(() => {
    const queryCode = query.get('c')

    if (queryCode) {
      dispatch(setUserToken(queryCode))
    }
    navigate(ROUTE_PATHS.MARKET)
  }, [])

  return <div>Redirect...</div>
}

export default RedirectLogin
