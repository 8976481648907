/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { MaxUint256 } from '@ethersproject/constants'
import { Link } from 'react-router-dom'
import { ArrowLeft, ArrowRight2, ArrowSwapVertical } from 'iconsax-react'
import { useWeb3React } from '@web3-react/core'
import { Button, CircularProgress } from '@mui/material'
import { ITokenTrade } from 'types/trade'
import { ethersToSerializedBigNumber } from 'utils/bigNumber'
import {
  fetchBNBBalance,
  fetchCaloOnchainBallance,
  fetchFitOnchainBallance,
} from 'store/reducers/walletOnchain'
import {
  estimateExactBNBForTokens,
  estimateExactTokensForBNB,
  estimateExactTokensForTokens,
  swapExactBNBForTokens,
  swapExactTokensForBNB,
  swapExactTokensForETHSupportingFeeOnTransferTokens,
  swapExactTokensForTokens,
  swapExactTokensForTokensSupportingFeeOnTransferTokens,
} from 'helpers/smc'
import { useCaloContract, useFitContract } from 'hooks/useContract'
import { getSwapDirection } from 'helpers/trade'
import { useAppDispatch, useAppSelector } from 'store'
import { commonClass } from 'theme'
import Images from 'images'
import { showToast } from 'store/reducers/common'
import { SWAP, SWAP_DIRECTIONS, TOKEN_TYPE } from 'types/common'
import SelectTokenModal from './SelectTokenModal'
import SwapFrom from './SwapFrom'
import SwapTo from './SwapTo'
import TradeConfirmModal from './ConfirmModal'
import SlippageModal from './SlippageModal'
import styles from './Trading.styles'

const SLIPPAGE_DEFAULT = 0.02

export const GAS_PRICE = 0.00000001 // 0.00000001 BNB (10 Gwei)
export const GAS_LIMIT = 350635

const Trading = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const walletOnchain = useAppSelector((state) => state.walletOnchain)
  const caloContract = useCaloContract()
  const fitContract = useFitContract()

  const [direction, setDirection] = useState(SWAP_DIRECTIONS.IN)
  const [tokenList, setTokenList] = useState<ITokenTrade[]>()
  const [tokenFrom, setTokenFrom] = useState<ITokenTrade>()
  const [tokenTo, setTokenTo] = useState<any>()
  const [amountFrom, setAmountFrom] = useState<string>('')
  const [isOpenModalSelectToken, setOpenModalSelectToken] = useState(false)
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [loadingEstimate, setLoadingEstimate] = useState(false)
  const [isOpenModalConfirm, setShowModalConfirm] = useState(false)
  const [isOpenModalSlippage, setShowModalSlippage] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [isApproved, setIsApproved] = useState(true)
  const [estimatePrice, setEstimatePrice] = useState<any | undefined>()
  const [swapExactToken, setSwapExactToken] = useState<ITokenTrade>({
    name: 'BNB',
    type: TOKEN_TYPE.BNB,
    balance: 0,
    icon: Images.BnbTokenIcon,
  })
  const [slippage, setSlippage] = useState(SLIPPAGE_DEFAULT)

  useEffect(() => {
    if (walletOnchain) {
      const { calo = 0, bnb = 0, fit = 0 } = walletOnchain
      const tokens = [
        {
          name: 'BNB',
          type: TOKEN_TYPE.BNB,
          balance: bnb,
          icon: Images.BnbTokenIcon,
        },
        {
          name: 'CALO',
          type: TOKEN_TYPE.CALO,
          balance: calo,
          icon: Images.CaloTokenIcon,
        },
        {
          name: 'FIT',
          type: TOKEN_TYPE.FIT,
          balance: fit,
          icon: Images.FitTokenIcon,
        },
      ]

      setTokenList(tokens)

      if (!tokenFrom) {
        setTokenFrom(tokens[0])
      } else {
        // update balance after swap
        const newTokenFrom = tokens.find((tk) => tk.type === tokenFrom.type)

        setTokenFrom(newTokenFrom)
      }

      if (!tokenTo) {
        setTokenTo(tokens[1])
      } else {
        // update balance after swap
        const newTokenTo = tokens.find((tk) => tk.type === tokenTo.type)

        setTokenTo(newTokenTo)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletOnchain])

  const getTokensBalance = (account: string) => {
    dispatch(fetchCaloOnchainBallance(account))
    dispatch(fetchFitOnchainBallance(account))
    dispatch(fetchBNBBalance(account))
  }

  useEffect(() => {
    if (account) {
      getTokensBalance(account)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const getCurrentContract = (tokenType: string) => {
    let contract = null

    switch (tokenType) {
      case TOKEN_TYPE.CALO:
        contract = caloContract
        break
      case TOKEN_TYPE.FIT:
        contract = fitContract
        break
      default:
        contract = null
    }

    return contract
  }

  const checkApproveToken = async (
    contract: any,
    spender: string,
    walletAddress: string,
  ) => {
    const allowance = await contract.allowance(walletAddress, spender)
    const totalApproved = parseFloat(ethersToSerializedBigNumber(allowance))

    setIsApproved(totalApproved > 0)
  }

  const handleApproveToken = async () => {
    if (tokenFrom) {
      setLoadingApprove(true)
      const contract = getCurrentContract(tokenFrom.type)

      if (contract) {
        try {
          const tx = await contract.approve(
            process.env.REACT_APP_PANCAKE_ROUTER_CONTRACT_ADDRESS as string,
            MaxUint256,
          )

          await tx.wait()

          setLoadingApprove(false)
        } catch (error) {
          setLoadingApprove(false)
        }
      }
    }
  }

  const getEstimatePrice = async (amountFrom: any, swapExactToken: any) => {
    let totalReceived = '0'
    const swapMethod = getSwapDirection(tokenFrom, tokenTo, swapExactToken)

    if (swapMethod === SWAP.SWAP_EXACT_BNB_FOR_CALO) {
      // swap bnb to calo
      totalReceived = await estimateExactBNBForTokens(
        ethers.utils.parseUnits(amountFrom, 'ether'),
        [
          process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
          process.env.REACT_APP_CALO_CONTRACT_ADDRESS,
        ],
        Number(slippage),
      )
    } else if (swapMethod === SWAP.SWAP_EXACT_BNB_FOR_FIT) {
      // swap bnb to fit
      totalReceived = await estimateExactBNBForTokens(
        ethers.utils.parseUnits(amountFrom, 'ether'),
        [
          process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
          process.env.REACT_APP_FIT_CONTRACT_ADDRESS,
        ],
        Number(slippage),
      )
    } else if (swapMethod === SWAP.SWAP_EXACT_CALO_FOR_BNB) {
      // swap calo to bnb
      totalReceived = await estimateExactTokensForBNB(
        ethers.utils.parseUnits(amountFrom, 'ether'),
        [
          process.env.REACT_APP_CALO_CONTRACT_ADDRESS,
          process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
        ],
        Number(slippage),
      )
    } else if (swapMethod === SWAP.SWAP_EXACT_FIT_FOR_BNB) {
      // swap fit to bnb
      totalReceived = await estimateExactTokensForBNB(
        ethers.utils.parseUnits(amountFrom, 'ether'),
        [
          process.env.REACT_APP_FIT_CONTRACT_ADDRESS,
          process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
        ],
        Number(slippage),
      )
    } else if (swapMethod === SWAP.SWAP_EXACT_CALO_FOR_FIT) {
      // swap calo to fit
      totalReceived = await estimateExactTokensForTokens(
        ethers.utils.parseUnits(amountFrom, 'ether'),
        [
          process.env.REACT_APP_CALO_CONTRACT_ADDRESS,
          process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
          process.env.REACT_APP_FIT_CONTRACT_ADDRESS,
        ],
        Number(slippage),
      )
    } else if (swapMethod === SWAP.SWAP_EXACT_FIT_FOR_CALO) {
      // swap fit to calo
      totalReceived = await estimateExactTokensForTokens(
        ethers.utils.parseUnits(amountFrom, 'ether'),
        [
          process.env.REACT_APP_FIT_CONTRACT_ADDRESS,
          process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
          process.env.REACT_APP_CALO_CONTRACT_ADDRESS,
        ],
        Number(slippage),
      )
    }

    return totalReceived
  }

  const calculateSwapPrice = async (balance: any, token: ITokenTrade) => {
    setLoadingEstimate(true)
    try {
      const amountTo = await getEstimatePrice(
        parseFloat(balance).toString(),
        token,
      )

      setEstimatePrice(amountTo)
    } catch (error) {
      dispatch(
        showToast({
          title: 'Error, please try again',
        }),
      )
    }

    setLoadingEstimate(false)
  }

  const handleTokenValueChange = (token: ITokenTrade) => {
    setSwapExactToken(token)
    if (
      parseFloat(amountFrom) > 0 &&
      tokenFrom?.balance &&
      parseFloat(amountFrom) <= tokenFrom.balance
    ) {
      calculateSwapPrice(amountFrom, token)
    } else {
      setEstimatePrice('0')
    }
  }

  const handleCalculateSwapAllToken = () => {
    if (
      tokenFrom &&
      tokenFrom?.type === TOKEN_TYPE.BNB &&
      tokenFrom?.balance > 0
    ) {
      // swap from bnb
      const transactionFee = GAS_PRICE * GAS_LIMIT
      const maxBalanceBnb = +tokenFrom?.balance - transactionFee

      if (maxBalanceBnb <= 0) {
        dispatch(
          showToast({
            title: 'Error: insufficient funds for intrinsic transaction cost',
          }),
        )
      } else {
        setAmountFrom(maxBalanceBnb.toString())
      }
      calculateSwapPrice(maxBalanceBnb, tokenFrom)
    } else if (tokenFrom && tokenFrom?.balance > 0) {
      // swap from calo or fit
      setAmountFrom(tokenFrom?.balance?.toString())
      calculateSwapPrice(tokenFrom?.balance, tokenFrom)
    } else {
      dispatch(
        showToast({
          title: 'Insufficient fund',
        }),
      )
    }
  }
  const handleSwapToken = async () => {
    setLoading(true)
    const { amountFrom = 0 } = data
    const swapMethod = getSwapDirection(tokenFrom, tokenTo, swapExactToken)
    let tx: any = null

    try {
      if (account && swapMethod === SWAP.SWAP_EXACT_BNB_FOR_CALO) {
        tx = await swapExactBNBForTokens(
          account,
          ethers.utils.parseUnits(amountFrom, 'ether'),
          [
            process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
            process.env.REACT_APP_CALO_CONTRACT_ADDRESS,
          ],
          Number(slippage),
        )
      } else if (account && swapMethod === SWAP.SWAP_EXACT_BNB_FOR_FIT) {
        tx = await swapExactBNBForTokens(
          account,
          ethers.utils.parseUnits(amountFrom, 'ether'),
          [
            process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
            process.env.REACT_APP_FIT_CONTRACT_ADDRESS,
          ],
          Number(slippage),
        )
      } else if (account && swapMethod === SWAP.SWAP_EXACT_CALO_FOR_BNB) {
        tx = await swapExactTokensForETHSupportingFeeOnTransferTokens(
          account,
          ethers.utils.parseUnits(amountFrom, 'ether'),
          [
            process.env.REACT_APP_CALO_CONTRACT_ADDRESS,
            process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
          ],
          Number(slippage),
        )
      } else if (account && swapMethod === SWAP.SWAP_EXACT_FIT_FOR_BNB) {
        tx = await swapExactTokensForBNB(
          account,
          ethers.utils.parseUnits(amountFrom, 'ether'),
          [
            process.env.REACT_APP_FIT_CONTRACT_ADDRESS,
            process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
          ],
          Number(slippage),
        )
      } else if (account && swapMethod === SWAP.SWAP_EXACT_CALO_FOR_FIT) {
        // swap calo to fit
        tx = await swapExactTokensForTokensSupportingFeeOnTransferTokens(
          account,
          ethers.utils.parseUnits(amountFrom, 'ether'),
          [
            process.env.REACT_APP_CALO_CONTRACT_ADDRESS,
            process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
            process.env.REACT_APP_FIT_CONTRACT_ADDRESS,
          ],
          Number(slippage),
        )
      } else if (account && swapMethod === SWAP.SWAP_EXACT_FIT_FOR_CALO) {
        // swap fit to calo
        tx = await swapExactTokensForTokens(
          account,
          ethers.utils.parseUnits(amountFrom, 'ether'),
          [
            process.env.REACT_APP_FIT_CONTRACT_ADDRESS,
            process.env.REACT_APP_WBNB_CONTRACT_ADDRESS,
            process.env.REACT_APP_CALO_CONTRACT_ADDRESS,
          ],
          Number(slippage),
        )
      }
      await tx?.wait()
      account && (await getTokensBalance(account))
      setAmountFrom('')
      setEstimatePrice('')
      setLoading(false)
      setShowModalConfirm(false)

      dispatch(
        showToast({
          title:
            'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
        }),
      )
    } catch (error) {
      setLoading(false)
      setShowModalConfirm(false)

      dispatch(
        showToast({
          title: 'Error, Please try again',
        }),
      )
    }
  }

  const handleSwitchTransfer = () => {
    const drtValue =
      direction === SWAP_DIRECTIONS.IN
        ? SWAP_DIRECTIONS.OUT
        : SWAP_DIRECTIONS.IN
    const newTokenFrom = { ...tokenTo }
    const newTokenTo = { ...tokenFrom }

    setDirection(drtValue)
    setSwapExactToken(newTokenFrom)
    setTokenFrom(newTokenFrom)
    setTokenTo(newTokenTo)
    // reset data
    setAmountFrom('')
    setEstimatePrice('')
  }

  const handleSelectToken = (token: ITokenTrade) => {
    setOpenModalSelectToken(false)
    if (token.type !== tokenFrom?.type && direction === SWAP_DIRECTIONS.IN) {
      setAmountFrom('')
      setTokenFrom(token)
      setSwapExactToken(token)
      setEstimatePrice('')
    }
    if (direction === SWAP_DIRECTIONS.OUT) {
      setTokenTo(token)

      // if (tokenFrom && +amountFrom > 0) {
      //   calculateSwapPrice(amountFrom, tokenFrom)
      // } else {
      setAmountFrom('')
      setEstimatePrice('')
      //}
    }

    if (account && token.type === TOKEN_TYPE.CALO) {
      checkApproveToken(
        caloContract,
        process.env.REACT_APP_PANCAKE_ROUTER_CONTRACT_ADDRESS as string,
        account,
      )
    } else {
      setIsApproved(true)
    }
  }

  const handleShowConfirmTrade = () => {
    if (walletOnchain?.bnb <= 0) {
      dispatch(
        showToast({
          title: 'Error: insufficient funds for intrinsic transaction cost',
        }),
      )
    } else {
      setData({
        direction,
        tokenFrom,
        tokenTo,
        fee: 0,
        amountFrom,
        amountEstimate: estimatePrice,
      })
      setShowModalConfirm(true)
    }
  }

  const formInvalid =
    loadingEstimate ||
    tokenFrom?.type === tokenTo?.type ||
    +amountFrom === 0 ||
    +estimatePrice <= 0

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      <div css={styles.contentWrapper}>
        <div css={styles.pageHeader}>
          <Link to='/wallet/onchain' css={styles.iconBack}>
            <ArrowLeft size='24' color='#D0E0F7' className='spending' />
          </Link>
          <h3>Trade</h3>
        </div>
        <div>
          <div css={styles.transferBox}>
            <SwapFrom
              amount={amountFrom}
              token={tokenFrom}
              setAmount={setAmountFrom}
              setShowModalSelectToken={setOpenModalSelectToken}
              setDirection={setDirection}
              setAllToken={handleCalculateSwapAllToken}
              onBlur={() => {
                tokenFrom && handleTokenValueChange(tokenFrom)
              }}
            />
          </div>
          <div css={styles.transferSwitch}>
            <Button variant='text' onClick={handleSwitchTransfer}>
              <ArrowSwapVertical size='40' color='#D0E0F7' />
            </Button>
            <div css={styles.loadingEstimate}>
              {loadingEstimate && <CircularProgress color='inherit' />}
            </div>
          </div>
          <div css={styles.transferBox}>
            {tokenList && (
              <SwapTo
                amount={estimatePrice}
                token={tokenTo || tokenList[1]}
                setShowModalSelectToken={setOpenModalSelectToken}
                setDirection={setDirection}
              />
            )}
          </div>
          <div css={styles.slippage}>
            <div>Slippage Tolerance</div>
            <div
              css={styles.slippageValue}
              onClick={() => setShowModalSlippage(true)}
            >
              <span>{Number(slippage * 100).toFixed(1)}%</span>
              <span css={styles.slippageIcon}>
                <ArrowRight2 size='20' />
              </span>
            </div>
          </div>
          <div>
            {!isApproved ? (
              <Button
                onClick={handleApproveToken}
                css={[commonClass.appButton, styles.confirmBtn]}
              >
                {!!loadingApprove && <CircularProgress size={18} />}
                APPROVE
              </Button>
            ) : (
              <Button
                onClick={handleShowConfirmTrade}
                css={[commonClass.appButton, styles.confirmBtn]}
                disabled={formInvalid}
              >
                TRADE
              </Button>
            )}
          </div>
        </div>
      </div>
      <SelectTokenModal
        tokenList={tokenList}
        isOpen={isOpenModalSelectToken}
        setShowModal={setOpenModalSelectToken}
        onSelectToken={handleSelectToken}
      />
      <SlippageModal
        slippage={slippage}
        isOpen={isOpenModalSlippage}
        setShowModal={setShowModalSlippage}
        onConfirm={(value: any) => {
          setSlippage(value)
          setShowModalSlippage(false)
          tokenFrom && handleTokenValueChange(tokenFrom)
        }}
      />
      {data && (
        <TradeConfirmModal
          isOpen={isOpenModalConfirm}
          data={data}
          setShowModal={setShowModalConfirm}
          isLoading={loading}
          onConfirm={handleSwapToken}
        />
      )}
    </div>
  )
}

export default Trading
