import { ethers } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
/**
 * Method to format the display of wei given an ethers.BigNumber object
 * Note: does NOT round
 */
export const formatBigNumber = (
  number: ethers.BigNumber,
  displayDecimals = 18,
  decimals = 18,
) => {
  const remainder = number.mod(
    ethers.BigNumber.from(10).pow(decimals - displayDecimals),
  )

  return formatUnits(number.sub(remainder), decimals)
}

/**
 * Method to format the display of wei given an ethers.BigNumber object with toFixed
 * Note: rounds
 */
export const formatBigNumberToFixed = (
  number: ethers.BigNumberish,
  displayDecimals = 18,
  decimals = 18,
) => {
  const formattedString = formatUnits(number, decimals)

  return (+formattedString).toFixed(displayDecimals)
}

/**
 * Formats a FixedNumber like BigNumber
 * i.e. Formats 9763410526137450427.1196 into 9.763 (3 display decimals)
 */
export const formatFixedNumber = (
  number: { toString: () => { (): any; new(): any; split: { (arg0: string): [any]; new(): any } } },
  displayDecimals = 18,
  decimals = 18,
) => {
  // Remove decimal
  const [leftSide] = number.toString().split('.')

  return formatBigNumber(
    ethers.BigNumber.from(leftSide),
    displayDecimals,
    decimals,
  )
}

export const formatNumber = (num = 0, digits = 3) => {
  const multiplier = Math.pow(10, digits)
  const adjustedNum = num * multiplier
  const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum)

  const number = truncatedNum / multiplier

  const internationalNumberFormat = new Intl.NumberFormat('en-US')

  return internationalNumberFormat.format(number)
}
