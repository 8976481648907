
const ChainId = {
  MAINNET: 56,
  TESTNET: 97,
}

export const BSC_BLOCK_TIME = 3

export const BASE_BSC_SCAN_URLS: any = {
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com',
}

export const BASE_BSC_SCAN_URL =
  process.env.REACT_APP_CHAIN_ID ? BASE_BSC_SCAN_URLS[process.env.REACT_APP_CHAIN_ID] : BASE_BSC_SCAN_URLS[ChainId.MAINNET]
