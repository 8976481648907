import { createSlice } from '@reduxjs/toolkit'
import { getTransactionHistoryApi, cancelTransactionApi, getTransactionApi } from 'api/wallet'
import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'

export const TYPE = 'TRANSACTION'

export const fetchTransactionHistory = createCallApiAsyncAction(
  TYPE + '/fetchTransactionHistory',
  getTransactionHistoryApi,
)

export const cancelTransaction = createCallApiAsyncAction(
  TYPE + '/cancelTransaction',
  cancelTransactionApi,
)

export const getTransactionDetail = createCallApiAsyncAction(
  TYPE + '/getTransactionDetail',
  getTransactionApi,
)

interface TransactionState {
  loading: boolean
  data: any[]
}

const initialState: TransactionState = {
  loading: true,
  data: [],
}

const transactionSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTransactionHistory.pending, (state, action) => {
      state.loading = true
      state.data = []
    })
    builder.addCase(fetchTransactionHistory.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.loading = false
        state.data = response?.data || []
      } else {
        state.data = []
      }
    })
  },
})

export default transactionSlice.reducer
