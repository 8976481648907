/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import {
  ArrowCircleDown,
  ArrowCircleDown2,
  ArrowCircleUp,
  ArrowCircleUp2,
  ArrangeHorizontalCircle,
} from 'iconsax-react'
import { fetchWalletBallance } from 'store/reducers/wallet'

import { useAppDispatch, useAppSelector } from 'store'
import styles from './OnChainWallet.styles'
import ModalScanAddress from './ModalScanAddress'
import ModalSelectToken from '../transfer-external/ModalSelectToken'

const WalletMenu = () => {
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector((state) => state.auth.token)
  const [isExpandMenu, setExpandMenu] = useState(false)
  const [openSelect, setOpenSelect] = useState(false)
  const [openScanAddress, setOpenScanAddress] = useState(false)

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchWalletBallance({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  return (
    <Box
      css={[
        styles.menuWrapper,
        css`
          gap: ${isExpandMenu ? '30px' : '60px'};
        `,
      ]}
    >
      <div css={styles.menuItem}>
        <div css={styles.menuItemIcon} onClick={() => setOpenScanAddress(true)}>
          <ArrowCircleDown2 size='34' color='#D0E0F7' className='spending' />
        </div>
        <div>Receive</div>
      </div>
      {isExpandMenu ? (
        <Box css={styles.menuExpand}>
          <Box css={styles.menuExpandBgIcon}></Box>
          <Link to='/wallet/transfer' css={styles.menuItem}>
            <div css={styles.menuItemIcon}>
              <ArrowCircleDown size='34' color='#D0E0F7' className='spending' />
            </div>
            To Spending
          </Link>
          <a css={styles.menuItem} onClick={() => setOpenSelect(true)}>
            <div css={styles.menuItemIcon}>
              <ArrowCircleUp size='34' color='#D0E0F7' className='external' />
            </div>
            To External
          </a>
        </Box>
      ) : (
        <div css={styles.menuItem} onClick={() => setExpandMenu(true)}>
          <div css={styles.menuItemIcon}>
            <ArrowCircleUp2 size='34' color='#D0E0F7' className='spending' />
          </div>
          <div>Transfer</div>
        </div>
      )}

      <Link to='/wallet/trade' css={styles.menuItem}>
        <div css={styles.menuItemIcon}>
          <ArrangeHorizontalCircle
            size='34'
            color='#D0E0F7'
            className='external'
          />
        </div>
        Trade
      </Link>

      <ModalSelectToken
        isOpen={openSelect}
        handleClose={() => setOpenSelect(false)}
      />

      <ModalScanAddress
        isOpen={openScanAddress}
        onClose={() => setOpenScanAddress(false)}
      />
    </Box>
  )
}

export default WalletMenu
