import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  box: css`
    box-sizing: border-box;
    padding: 24px;
    text-align: center;
    * {
      box-sizing: border-box;
    }
  `,
  title: css`
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: ${baseColors.white};
  `,

  inputWrap: css`
    padding: 8px 0;
    text-align: left;
    position: relative;
    input {
      height: 44px;
      background: ${baseColors.appBg};
      border-radius: 8px;
      border: 0;
      display: block;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      letter-spacing: 0.01em;
      color: ${baseColors.white};
      padding: 0 12px;

      &:focus-visible,
      &:focus,
      &:active {
        outline: 0;
      }
    }
  `,
  button: css`
    width: 160px;
    height: 40px;
    background: ${baseColors.primaryLinearGradient};
    border-radius: 36px;
    border: 0;

    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    margin-top: 30px;

    color: ${baseColors.white};
    cursor: pointer;
  `,
  textBtn: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    color: ${baseColors.linkText};
    cursor: pointer;
  `,
  sendCode: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${baseColors.primaryColor};
    margin: 0;

    position: absolute;
    right: 12px;
    top: 24px;

    cursor: pointer;
  `,
  errorText: css`
    font-size: 12px;
    margin: 0;
    padding-top: 4px;
    padding-left: 3px;
    color: red;
  `,
  loading: css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${baseColors.blur};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

export default styles
