// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from 'firebase/remote-config'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESS_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app)

remoteConfig.settings.minimumFetchIntervalMillis = 3600000
export const fetchFirebaseConfigApi = async () => {
  try {
    await fetchAndActivate(remoteConfig)

    return {
      apiStatus: 1,
      sharedSecret: getValue(remoteConfig, 'sharedSecret').asString() || '',
    }
  } catch (e) {
    return {
      apiStatus: 0,
      message: 'firebase error',
    }
  }
}
