/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { css } from '@emotion/react'
const styles = {
  wrapper: css`
    position: relative;
    display: flex;
    justify-content: center;
  `,
  placeHolderStyle: css`
    position: absolute;
  `,
  opacity0: css`
    opacity: 0;
  `,
}

interface IProps {
  placeholderImg: string
  style: any
  placeholderStyle: any
  src: string
}
const ImagePlaceholder = ({
  placeholderImg,
  placeholderStyle,
  style,
  src,
}: IProps) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div css={[style, styles.wrapper]}>
      <img
        alt='placeholder'
        css={[
          placeholderStyle,
          styles.placeHolderStyle,
          isLoaded && styles.opacity0,
        ]}
        src={placeholderImg}
      />
      <img alt='img' onLoad={() => setIsLoaded(true)} css={style} src={src} />
    </div>
  )
}

export default ImagePlaceholder
