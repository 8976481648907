/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import styles from './WalletNavTop.styles'

interface IProps {
  active: string
}

const WalletNavTop = ({ active }: IProps) => {
  return (
    <Box css={styles.menuWrapper}>
      <Link
        to='/wallet/spending'
        css={active === 'SPENDING' ? styles.menuActive : ''}
      >
        Spending
      </Link>
      <Link
        to='/wallet/onchain'
        css={active === 'ONCHAIN' ? styles.menuActive : ''}
      >
        Wallet
      </Link>
    </Box>
  )
}

export default WalletNavTop
