/** @jsxImportSource @emotion/react */
import { Modal, Box, Button } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { QRCodeCanvas } from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { truncateAddress } from 'helpers/text'
import { useAppDispatch } from 'store'
import { showToast } from 'store/reducers/common'
import { commonClass } from 'theme'
import styles from './ModalScanAddress.styles'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const ModalScanAddress = ({ isOpen, onClose }: IProps) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  if (!account) {
    return null
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box css={styles.modalWrapper}>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Receive</div>
        </div>

        <div css={styles.qrCodeAddress}>
          <QRCodeCanvas value={account || ''} size={200} />
        </div>
        <div css={styles.scanNote}>Scan address to receive payment</div>
        <div css={styles.shortAddress}>
          {truncateAddress(account || '', 15, '...')}
        </div>
        <div css={styles.action}>
          <CopyToClipboard
            text={account}
            onCopy={() =>
              dispatch(
                showToast({
                  title: 'Copied',
                }),
              )
            }
          >
            <Button css={[commonClass.appButton, styles.copyBtn]}>
              COPY ADDRESS
            </Button>
          </CopyToClipboard>
        </div>
      </Box>
    </Modal>
  )
}

export default ModalScanAddress
