
export const truncateAddress = (
  fullText: string,
  textLen: number,
  separator: string,
) => {
  if (!fullText) return
  // eslint-disable-next-line consistent-return
  if (fullText.length <= textLen) return fullText

  separator = separator || '...'

  const sepLen = separator.length
  const charsToShow = textLen - sepLen
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  // eslint-disable-next-line consistent-return
  return (
    fullText.substr(0, frontChars) +
    separator +
    fullText.substr(fullText.length - backChars)
  )
}
