/** @jsxImportSource @emotion/react */
import React from 'react'
import { Modal, Box } from '@mui/material'
import { ConnectorNames } from 'types/connector'
import logoMetamask from 'images/img/logoMetamask.svg'
// import logoTrustWallet from 'images/img/logoTrustWallet.svg'
import useAuth from 'hooks/useAuth'
import styles from './ConnectWalletModal.styles'

export const connectorLocalStorageKey = 'connectorIdv2'
export const walletLocalStorageKey = 'wallet'

const ModalConnectWallet = (props: any) => {
  const { visible, onCancel } = props

  const { login } = useAuth()

  const metamaskWalletConfig = {
    title: 'Metamask',
    connectorId: ConnectorNames.Injected,
    priority: 1,
  }

  // const trustWalletConfig = {
  //   title: 'Trust Wallet',
  //   connectorId: ConnectorNames.Injected,
  // }

  const onConnectWallet = (config: any) => {
    login(config.connectorId)
    localStorage.setItem(walletLocalStorageKey, config.title)
    localStorage.setItem(connectorLocalStorageKey, config.connectorId)
  }

  return (
    <Modal
      open={visible}
      onClose={onCancel}
      aria-labelledby='child-modal-title'
      aria-describedby='child-modal-description'
    >
      <Box css={styles.modalWrapper}>
        <div css={styles.header}>
          <div css={styles.title}>Connect Wallet</div>
        </div>
        <div css={styles.btnWrapper}>
          <div
            css={styles.btnConnect}
            onClick={() => onConnectWallet(metamaskWalletConfig)}
          >
            <div className='logo'>
              <img src={logoMetamask} alt='logoMetamask' />
            </div>
          </div>

          {/* <div
            css={styles.btnConnect}
            onClick={() => onConnectWallet(trustWalletConfig)}
          >
            <div className='logo'>
              <img src={logoTrustWallet} alt='logoTrustWallet' />
            </div>
          </div> */}
        </div>
        <div css={styles.textHelper}>
          Start by connecting with Metamask wallet. Be sure to store your
          private keys or seed phrase securely. Never share them with anyone.
        </div>
      </Box>
    </Modal>
  )
}

export default ModalConnectWallet
