import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css`
    display: flex;
  `,
  contentWrapper: css`
    padding: 16px;
    flex: 1;
  `,
  pageHeader: css`
    display: flex;
    align-items: center;
    margin: 0 0 30px 0;
  `,
  iconBack: css`
    margin-right: 10px;
  `,
  transferBox: css`
    margin-right: 10px;
  `,
  transferSwitch: css`
    margin: 20px 0;
    text-align: center;
    position: relative;
    @media only screen and (min-width: 768px) {
      margin: 30px 0;
    }
  `,
  loadingEstimate: css`
    position: absolute;
    left: 50%;
    margin-left: -20px;
    z-index: 1;
    top: 6px;
  `,
  formWrapper: css`
    margin: 20px 0 0 0;
    @media only screen and (min-width: 768px) {
      margin-top: 50px;
    }
  `,
  formControl: css`
    margin: 0 0 20px 0;
    .Mui-disabled.MuiOutlinedInput-root{
      background: #484861;
      border-radius: 12px;
    }
  `,
  formLabel: css`
    margin: 0 0 5px 0;
    color:${baseColors.neutralColor7};
    font-size: 14px;
  `,
  inputWrapper: css`
    background: none;
    color:#fff;
    border: none;
    border-radius: 12px;
    font-size: 48px;
    text-align: center;
    .MuiInputBase-input{
      text-align: center;
      padding: 0;
      
    }
    .MuiInputBase-input::placeholder{
      color:#fff
    }
    .MuiSelect-select{
      text-align: center;
      color:#fff;
      display: flex;
      align-items: center;
      .icon{
        max-width: 24px;
        margin-right: 10px;
      }
      
    }
    .MuiOutlinedInput-notchedOutline{
      border-color: transparent !important;
    }
  `,
  tokenSelect: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background:${baseColors.cardBg};
    border-radius: 54px;
    padding: 10px 20px;
    max-width: 150px;
    margin: 0 auto 10px auto;
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      margin: 0 auto 20px auto;
    }
  `,
  tokenIcon: css`
    width: 30px;
    height: 30px;
    img{
      max-width: 30px;
    }
  `,
  tokenName: css`
    min-width: 80px;
    text-align: center;
  `,
  validate: css`
    text-align: center;
    color:${baseColors.primaryColor};
  `,
  tokenMax: css`
    text-align: center;
    color:${baseColors.neutralColor6};
  `,
  tokenAll: css`
    color:${baseColors.primaryColor};
    margin-left: 10px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: 100%;
    max-width: 220px;
    margin: 50px auto 0;
    padding: 10px 0;
    display: block;
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      margin-top: 100px;
    }
  `,
  slippage: css`
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  slippageValue: css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,
  slippageIcon: css`
    /* margin-top: 2px; */
  `,
}

export default styles
