import { css } from '@emotion/react'

const styles = {
  title: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.0015em;
  `,
  mintWrap: css`
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,
  productWrap: css`
    width: calc((100% - 16px) / 2);
    margin-bottom: 16px;
  `,
  shoeStyles: css``,
}

export default styles
