import bigInt from 'big-integer'
import { ethers } from 'ethers'

import { getPancakeRouterContract } from 'utils/contractHelpers'

export const getSigner = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  return signer
}

// const slipage = 0.02;

export const swapExactBNBForTokens = async (
  walletAddress: string,
  amountIn: any,
  path: any,
  slippage: number,
  // eslint-disable-next-line max-params
) => {
  const signer = await getSigner()
  const pancakeRouterContract = await getPancakeRouterContract(signer)
  const amountOut = await getAmountOut(path, amountIn)

  const minAmountTokenOut = bigInt(Math.floor(amountOut * (1 - slippage)))

  try {

    const res = await pancakeRouterContract.swapExactETHForTokens(
      minAmountTokenOut?.toString(),
      path,
      walletAddress,
      Math.floor(Date.now() / 1000) + 1000,
      {
        from: walletAddress,
        // gas: 400000,
        value: amountIn,
      },
      // {
      //   gasLimit: ethers.utils.hexlify(200000),
      //   gasPrice: ethers.utils.parseUnits('10', 'gwei'),
      //   value: amountIn,
      // },
    )

    return res
  } catch (error) {
    throw error
  }
}

export const swapExactTokensForBNB = async (
  walletAddress: string,
  amountIn: any,
  path: any,
  slippage: number,
  // eslint-disable-next-line max-params
) => {
  const signer = await getSigner()

  const pancakeRouterContract = await getPancakeRouterContract(signer)
  const amountOut = await getAmountOut(path, amountIn)
  const mintAmountBNBOut = bigInt(Math.floor(amountOut * (1 - slippage)))

  try {
    const res = await pancakeRouterContract.swapExactTokensForETH(
      amountIn,
      mintAmountBNBOut.toString(),
      path,
      walletAddress,
      Math.floor(Date.now() / 1000) + 1000,
      {
        from: walletAddress,
        // gas: 400000,
        // gasLimit: ethers.utils.hexlify(250000),
        // gasPrice: ethers.utils.parseUnits('5', 'gwei'),
      },
    )

    return res
  } catch (error) {
    throw error
  }
}

export const swapExactTokensForETHSupportingFeeOnTransferTokens = async (
  walletAddress: string,
  amountIn: any,
  path: any,
  slippage: number,
  // eslint-disable-next-line max-params
) => {
  const signer = await getSigner()
  const pancakeRouterContract = await getPancakeRouterContract(signer)
  const amountOut = await getAmountOut(path, amountIn)
  const mintAmountBNBOut = bigInt(Math.floor(amountOut * (1 - slippage)))

  try {
    const res =
      await pancakeRouterContract.swapExactTokensForETHSupportingFeeOnTransferTokens(
        amountIn,
        mintAmountBNBOut.toString(),
        path,
        walletAddress,
        Math.floor(Date.now() / 1000) + 1000,
        {
          from: walletAddress,
          // gas: 400000,
          // gasLimit: ethers.utils.hexlify(250000),
          // gasPrice: ethers.utils.parseUnits('5', 'gwei'),
        },
      )

    return res
  } catch (error) {
    throw error
  }
}

export const swapExactTokensForTokens = async (
  walletAddress: string,
  amountIn: any,
  path: any,
  slippage: number,
  // eslint-disable-next-line max-params
) => {
  const signer = await getSigner()

  const pancakeRouterContract = await getPancakeRouterContract(signer)
  const amountOut = await getAmountOut(path, amountIn)
  const minAmountTokenOut = bigInt(Math.floor(amountOut * (1 - slippage)))

  try {
    const res = await pancakeRouterContract.swapExactTokensForTokens(
      amountIn,
      minAmountTokenOut.toString(),
      path,
      walletAddress,
      Math.floor(Date.now() / 1000) + 1000,
      {
        from: walletAddress,
      },
    )

    return res
  } catch (error) {
    throw error
  }
}

export const swapExactTokensForTokensSupportingFeeOnTransferTokens = async (
  walletAddress: string,
  amountIn: any,
  path: any,
  slippage: number,
  // eslint-disable-next-line max-params
) => {
  const signer = await getSigner()

  const pancakeRouterContract = await getPancakeRouterContract(signer)
  const amountOut = await getAmountOut(path, amountIn)
  const minAmountTokenOut = bigInt(Math.floor(amountOut * (1 - slippage)))

  try {
    const res =
      await pancakeRouterContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
        amountIn,
        minAmountTokenOut.toString(),
        path,
        walletAddress,
        Math.floor(Date.now() / 1000) + 1000,
        {
          from: walletAddress,
        },
      )

    return res
  } catch (error) {
    throw error
  }
}

export const estimateExactBNBForTokens = async (
  amountIn: any,
  path: any,
  slippage: any,
  // eslint-disable-next-line max-params
) => {

  const amountOut = await getAmountOut(path, amountIn)
  const minAmountTokenOut = bigInt(Math.floor(amountOut * (1 - slippage)))

  return ethers.utils.formatEther(minAmountTokenOut.toString())
}

export const estimateExactTokensForBNB = async (
  amountIn: any,
  path: any,
  slippage: any,
  // eslint-disable-next-line max-params
) => {
  const amountOut = await getAmountOut(path, amountIn)
  const mintAmountBNBOut = bigInt(Math.floor(amountOut * (1 - slippage)))

  return ethers.utils.formatEther(mintAmountBNBOut.toString())
}

export const estimateExactTokensForTokens = async (
  amountIn: any,
  path: any,
  slippage: any,
  // eslint-disable-next-line max-params
) => {
  const amountOut = await getAmountOut(path, amountIn)
  const minAmountTokenOut = bigInt(Math.floor(amountOut * (1 - slippage)))

  return ethers.utils.formatEther(minAmountTokenOut.toString())
}

export const getAmountOut = async (path: any, amountIn: any) => {

  const pancakeRouterContract = await getPancakeRouterContract()
  const res = await pancakeRouterContract.getAmountsOut(amountIn, path)

  return res[res.length - 1]
}
