/** @jsxImportSource @emotion/react */
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import styles from './FilterTabs.styles'
interface IProps {
  value: any
  onChange: (value: any) => void
  options: { value: any; label: string }[]
}
const FilterTabs = ({ value, onChange, options }: IProps) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(e, value) => onChange(value)}
      css={styles.tabWrapper}
    >
      {options.map((v) => (
        <ToggleButton key={v.value} css={styles.tabBtn} value={v.value}>
          {v.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default FilterTabs
