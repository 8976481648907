import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  dropdown: css`
    background: transparent;
    border: 0 !important;
    color: ${baseColors.secondaryColor3};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    &:before,
    &:after {
      border-bottom: 0 !important;
    }
    & .MuiSelect-icon {
      top: calc(50% - 0.55em);
    }
  `,
  dropdownMenu: css`
    .calo-dropdown-menu {
      & .MuiPaper-root {
        background: ${baseColors.imgBg} !important;

        box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
      }
    }
  `,
  item: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: ${baseColors.secondaryColor3};
    padding: 6px 0;
    margin: 0 16px;
    &.Mui-selected,
    &.Mui-selected:hover {
      background-color: transparent !important;
      font-weight: 600;
      color: ${baseColors.primaryColor4};
    }
    &:not(:last-child) {
      border-bottom: 1px solid #2c395b;
    }
  `,
}

export default styles
