/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import ProductItem from 'components/product-item/ProductItem'
import { NFT_TYPE } from 'types/common'
import { IShoe, IShoeMint } from 'types/nft'
import styles from './MintShoes.styles'
interface IProps {
  mints: Array<IShoeMint | null | undefined>
  style?: any
  title: string
  wrapStyle?: any
}
const MintShoes = ({ mints, style, title, wrapStyle }: IProps) => {
  const mintData = (mints || []).filter((v) => !!v)

  if (!mintData.length) {
    return null
  }

  return (
    <div css={css(style)}>
      <span css={styles.title}>{title}</span>
      <div css={styles.mintWrap}>
        {mintData.map(
          (shoeData) =>
            !!shoeData && (
              <div key={shoeData.shoeId} css={styles.productWrap}>
                <ProductItem
                  itemDetail={shoeData as IShoe}
                  type={NFT_TYPE.SHOE}
                  hideMetric={true}
                  wrapStyle={wrapStyle}
                />
              </div>
            ),
        )}
      </div>
    </div>
  )
}

export default MintShoes
