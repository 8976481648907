import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css`
    max-width: 800px;
    padding-top: 30px;
  `,
  pageHeader: css`
    display: flex;
    align-items: center;
  `,
  iconBack: css`
    margin-right: 10px;
  `,
  contentWrapper: css`
    gap: 16px;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 32px;
  `,
  noItem: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
  `,
  noItemText: css`
    color: ${baseColors.secondaryColor5};
  `,
}

export default styles
