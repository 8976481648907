/** @jsxImportSource @emotion/react */
import _get from 'lodash/get'
import { css } from '@emotion/react'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { capitalizeFirstLetter } from 'helpers/utils'
import { baseColors } from 'theme'
import styles from './ShoeboxCard.styles'
import ModalShoeboxAction from './ModalShoeboxAction'

interface IProps {
  shoeBoxId: number
  quality: string
  name: string
  image: string
}

const ShoeboxCard = ({ shoeBoxId, quality, name, image }: IProps) => {
  const qualityText = capitalizeFirstLetter(quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')

  const [open, setOpen] = useState(false)

  return (
    <>
      <div css={styles.boxWrapper} onClick={() => setOpen(true)}>
        <div
          css={[
            styles.boxHeader,
            css`
              color: ${qualityColor};
              svg path {
                fill: ${qualityColor};
              }
            `,
          ]}
        >
          {quality}
        </div>
        <div css={styles.boxBody}>
          <img src={image} alt='img' />
          <Typography color={qualityColor} css={styles.id}>
            #{shoeBoxId}
          </Typography>
        </div>
      </div>
      <ModalShoeboxAction
        isOpen={open}
        handleClose={() => setOpen(false)}
        image={image}
        quality={quality}
        shoeBoxId={shoeBoxId}
      />
    </>
  )
}

export default ShoeboxCard
