/** @jsxImportSource @emotion/react */
import { Select, MenuItem } from '@mui/material'
import { Global } from '@emotion/react'
import Images from 'images'
import styles from './Dropdown.styles'
interface IProps {
  value: any
  onChange: (value: any) => void
  options: { value: any; label: string }[]
  width?: number
  menuWidth?: number
}
const Dropdown = ({ value, onChange, options, width, menuWidth }: IProps) => {
  return (
    <>
      <Global styles={styles.dropdownMenu} />
      <Select
        variant={'standard'}
        css={styles.dropdown}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        IconComponent={Images.ArrowDown}
        MenuProps={{
          className: 'calo-dropdown-menu',
        }}
        sx={{ width }}
      >
        {options.map((v) => (
          <MenuItem
            sx={{ width: menuWidth }}
            key={v.value}
            css={styles.item}
            value={v.value}
          >
            {v.label}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default Dropdown
