/** @jsxImportSource @emotion/react */

import { ArrowDown2 } from 'iconsax-react'
import { ITokenTrade } from 'types/trade'
import { SWAP_DIRECTIONS } from 'types/common'
import { formatNumber } from 'helpers/formatBalance'
import styles from './Trading.styles'

interface IProps {
  amount: any
  token: ITokenTrade
  setShowModalSelectToken: (value: any) => void
  setDirection: (value: any) => void
}
const SwapTo = ({
  amount,
  token,
  setShowModalSelectToken,
  setDirection,
}: IProps) => {
  return (
    <>
      <div css={styles.formControl}>
        <div css={styles.inputWrapper}>{formatNumber(amount, 5)}</div>
      </div>
      <div
        css={styles.tokenSelect}
        onClick={() => {
          setShowModalSelectToken(true)
          setDirection(SWAP_DIRECTIONS.OUT)
        }}
      >
        <div css={styles.tokenIcon}>
          <img src={token.icon} alt={token?.name} />
        </div>
        <div css={styles.tokenName}>{token?.name}</div>
        <ArrowDown2 size='24' color='#D0E0F7' className='spending' />
      </div>
    </>
  )
}

export default SwapTo
