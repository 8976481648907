/** @jsxImportSource @emotion/react */
import _get from 'lodash/get'
import { css } from '@emotion/react'
import { IShoe } from 'types/nft'
import {
  capitalizeFirstLetter,
  getClassObj,
  getShoeAttribute,
} from 'helpers/utils'
import Images from 'images'
import { baseColors } from 'theme'
import styles from './AdvanceInfo.styles'
interface IProps {
  detail: IShoe
}
const AdvanceInfo = ({ detail }: IProps) => {
  const qualityText = capitalizeFirstLetter(detail?.quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')
  const { properties } = getShoeAttribute(detail)

  const { label: classLabel, value: classValue } = getClassObj(
    detail?.class || '',
  )

  const ClassIcon = Images.classIcon[classValue]

  return (
    <>
      <div css={styles.wrapper}>
        <div css={styles.info}>
          <label>Class</label>
          <p
            css={css`
              color: ${qualityColor};
              svg path {
                fill: ${qualityColor};
              }
            `}
          >
            <ClassIcon />
            {classLabel}
          </p>
        </div>
        <div css={styles.info}>
          <label>Level</label>
          <p>{detail?.level}</p>
        </div>
      </div>
      <div css={styles.properties}>
        {properties.map((attr) => (
          <div key={attr.label} css={styles.attributeWrap}>
            <div css={[styles.attributeIcon, css(attr.iconStyle)]}>
              <span
                css={[
                  styles.attributeTextIcon,
                  css({ color: attr.iconStyle.color }),
                ]}
              >
                {attr.label[0]}
              </span>
            </div>
            <span css={styles.attributeText}>{attr.value}</span>
          </div>
        ))}
      </div>
    </>
  )
}

export default AdvanceInfo
