import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css``,
  icon: css``,
  text: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${baseColors.secondaryColor3};
    text-transform: initial;
    margin: 0 6px;
  `,
  total: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;

    color: ${baseColors.primaryColor4};
  `,
}

export default styles
