import { css } from '@emotion/react'
const styles = {
  menuWrapper: css`
    display: flex;
    gap: 10px;
    justify-content: center;
    a{
      color: #fff;
      text-decoration: none;
      padding: 10px 20px;
    }
  `,
  menuActive: css`
    background: #25253D;
    border-radius: 24px;
  `,
}

export default styles
