import { css } from '@emotion/react'
import { baseColors } from 'theme'
const styles = {
  labelText: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${baseColors.secondaryColor2};
    margin-bottom: 2px;
  `,
  valueText: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${baseColors.neutralColor1};
    span {
      color: ${baseColors.secondaryColor2};
    }
  `,
}

export default styles
