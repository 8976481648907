/** @jsxImportSource @emotion/react */
import React from 'react'
import { Box } from '@mui/material'
import { Wallet1, EmptyWallet, ArrowSwapVertical } from 'iconsax-react'
import { TRANSFER_DIRECTION } from 'types/common'
import styles from './TransferSwitchDirection.styles'

interface IProps {
  direction: string
  switchDirection: () => void
}

interface IDirection {
  name?: string
}

const TransferSwitchDirection = ({ direction, switchDirection }: IProps) => {
  const renderIcon = (direction?: string) => {
    if (direction === 'Wallet') {
      return <Wallet1 size='24' color='#D0E0F7' className='iconWallet' />
    }

    return <EmptyWallet size='24' color='#D0E0F7' className='iconSpending' />
  }
  const drtWallet = {
    name: 'Wallet',
  }

  const drtSpending = {
    name: 'Spending',
  }

  let directionFrom: IDirection = {}
  let directionTo: IDirection = {}

  if (direction === TRANSFER_DIRECTION.DEPOSIT) {
    directionFrom = drtWallet
    directionTo = drtSpending
  }
  if (direction === TRANSFER_DIRECTION.WITHDRAW) {
    directionFrom = drtSpending
    directionTo = drtWallet
  }

  return (
    <Box css={styles.transferBox}>
      <div css={styles.transferWrapper}>
        <div css={styles.transferItem}>
          <div css={styles.transferItemIcon}>
            {renderIcon(directionFrom?.name)}
          </div>
          <div css={styles.transferItemLabel}>From</div>
          <div>{directionFrom?.name}</div>
        </div>
        <div css={styles.divider}></div>
        <div css={styles.transferItem}>
          <div css={styles.transferItemIcon}>
            {renderIcon(directionTo?.name)}
          </div>
          <div css={styles.transferItemLabel}>To</div>
          <div>{directionTo?.name}</div>
        </div>
      </div>
      <div css={styles.transferSwitch}>
        <button onClick={switchDirection} css={styles.btnSwitch}>
          <ArrowSwapVertical
            size='24'
            color='#D0E0F7'
            className='iconSpending'
          />
        </button>
      </div>
    </Box>
  )
}

export default TransferSwitchDirection
