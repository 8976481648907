import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css`
    display: flex;
  `,
  contentWrapper: css`
    padding: 16px;
    flex: 1;
  `,
  connectWalletWrapper: css`
    display: block;
    text-align: center;
    padding-top: 30px;
  `,
  walletInfo: css`
    background-color: ${baseColors.imgBg};
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 0 12px;
  `,
  walletInfoItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 20px 0;
    border-top: 1px solid #2c395b;
    &:first-of-type {
      border: 0;
    }
  `,
  walletAddress: css`
    background: ${baseColors.cardBg};
    border-radius: 24px;
    padding: 10px 20px;
    color: ${baseColors.darkGray70};
    cursor: pointer;
  `,
  bnbBalanceTop: css`
    font-size: 32px;
    color: ${baseColors.darkPrimary};
    font-weight: 700;
    margin-bottom: 15px;
  `,
  tokenIcon: css`
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
  `,
  accountWrapper: css`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin: 20px auto;
    text-align: center;
  `,
  menuWrapper: css`
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
    margin: 30px auto;
  `,
  menuItem: css`
    color: ${baseColors.darkGray70};
    text-decoration: none;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
  `,
  menuItemIcon: css`
    align-self: center;
    margin-bottom: 10px
  `,
  menuExpand: css`
    display: flex;
    position: relative;
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
    gap: 20px;
  `,
  menuExpandBgIcon: css`
    position: absolute;
    z-index: -1;
    height: 45px;
    width: 90%;
    left: 50%;
    margin-left: -45%;
    top: -5px;
    justify-content: space-between;
    background-color: ${baseColors.cardBg};
    border-radius: 24px;
  `,
}

export default styles
