import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css`
    display: flex;
  `,
  pageHeader: css`
    display: flex;
    align-items: center;
  `,
  iconBack: css`
    margin-right: 10px;
  `,
  contentWrapper: css`
    padding: 16px;
    flex: 1;
  `,
  walletInfo: css`
    background-color: ${baseColors.imgBg};
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 0 12px;
  `,
  walletInfoItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    border-top: 1px solid #2c395b;
    &:first-of-type {
      border: 0;
    }
  `,
  tokenIcon: css`
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
  `,
  formWrapper: css`
    margin: 50px 0 0 0;
  `,
  formControl: css`
    margin: 0 0 20px 0;
    .Mui-disabled.MuiOutlinedInput-root{
      background: #484861;
      border-radius: 12px;
    }
  `,
  formLabel: css`
    margin: 0 0 5px 0;
    color:${baseColors.neutralColor7};
    font-size: 14px;
  `,
  confirmBtn: css`
    width: 100%;
    max-width: 220px;
    margin: 50px auto 0;
    padding: 10px 0;
    display: block;
    cursor: pointer;
  `,
  inputWrapper: css`
    background: #25253D;
    color:#fff;
    border: none;
    border-radius: 12px;
    .MuiSelect-select{
      color:#fff;
      display: flex;
      align-items: center;
      .icon{
        max-width: 24px;
        margin-right: 10px;
      }
      
    }
    .MuiOutlinedInput-notchedOutline{
        border-color: transparent !important;
      }
  `,
  select: css`
    color:#fff;
  
  `,
  selectItem: css`
    color:#fff;
    .icon{
      max-width: 24px;
      margin-right: 10px;
    }
  `,
  dropdown: css`
    background: transparent;
    border: 0 !important;
    color: ${baseColors.secondaryColor3};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    &:before,
    &:after {
      border-bottom: 0 !important;
    }
    & .MuiSelect-icon {
      top: calc(50% - 0.55em);
    }
    `,
  dropdownMenu: css`
    .calo-dropdown-menu {
      & .MuiPaper-root {
        background: ${baseColors.imgBg} !important;

        box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
      }
      .Mui-selected{
        background-color: rgb(110 110 110 / 8%)!important;
      }
    
    }
  `,
  item: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: ${baseColors.secondaryColor3};
    padding: 6px 0;
    margin: 0 16px;
    &.Mui-selected,
    &.Mui-selected:hover {
      background-color: transparent !important;
      font-weight: 600;
      color: ${baseColors.primaryColor4};
    }
    &:not(:last-child) {
      border-bottom: 1px solid #2c395b;
    }
  `,
  availableBalance: css`
    font-size: 14px;
    color:${baseColors.neutralColor7};
    margin: 10px 0 0 0;
  `,
  validate: css`
    margin-top: 10px;
    color:${baseColors.primaryColor};
  `,
}

export default styles
