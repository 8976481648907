/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletModal from 'components/connect-wallet-modal/ConnectWalletModal'
import { commonClass } from 'theme'

type Width = 'fit' | 'full'

interface IConnectWalletButtonProps {
  width?: Width
  childrenText?: string
}

export const connectorLocalStorageKey = 'connectorIdv2'
export const walletLocalStorageKey = 'wallet'

const ConnectWalletButton = ({
  width = 'fit',
  childrenText = 'Connect Wallet',
}: IConnectWalletButtonProps) => {
  const { account } = useWeb3React()

  const [openConnect, setOpenConnect] = useState(false)

  const onOpenConnect = () => {
    setOpenConnect(true)
  }

  useEffect(() => {
    if (account) {
      setOpenConnect(false)
    }
  }, [account])

  return (
    <>
      <Button
        onClick={onOpenConnect}
        css={[commonClass.appButton]}
        style={{ minWidth: '200px' }}
      >
        {childrenText}
      </Button>
      <ConnectWalletModal
        visible={openConnect}
        onCancel={() => setOpenConnect(false)}
      />
    </>
  )
}

export default ConnectWalletButton
