/** @jsxImportSource @emotion/react */
import { Button } from '@mui/material'
import Images from 'images'

import styles from './FilterButton.styles'
interface IProps {
  total: number
  onClick: () => void
}
const FilterButton = ({ onClick, total }: IProps) => {
  return (
    <Button css={styles.wrapper} onClick={onClick}>
      <Images.FilterIcon css={styles.icon} />

      <span css={styles.text}>Filter</span>

      <span css={styles.total}>{total || 0}</span>
    </Button>
  )
}

export default FilterButton
