import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'
const styles = {
  dialog: css`
    .select-token-paper {
      &.MuiDialog-paper{
        border-radius: 12px;
      }
     
    }
  `,
  wrapper: css`
    padding: 24px 20px 0 20px;
    background-color: ${baseColors.cardBg};
    width: 200px;
    @media only screen and (min-width: ${mobileWidth}px) {
      width: 300px;
    }
  `,
  title: css`
    margin-bottom: 10px;
  `,
  listItem: css`
    border-bottom: 1px solid #2C395B;
    padding: 15px 0;
    &:last-child {
      border: none
    }
  `,

  icon: css`
    width: 30px;
    margin-right: 10px;
  `,

}

export default styles
