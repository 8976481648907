import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  modalWrapper: css`
    width: 100%;
    height: 100%;
    max-width: 460px;
    margin: 0 auto;
    background-color: ${baseColors.cardBg};
    color: ${baseColors.neutralColor1};
    position: relative;
    border-radius: 16px 16px 0px 0px;
  `,
  contentHeader: css`
    padding: 12px 16px;
    justify-content: space-between;
  `,
  title: css`
    font-weight: 600;
    font-size: 21.0753px;
    line-height: 25px;
    letter-spacing: 0.0015em;
    font-family: unset;
  `,
  contentBody: css`
    text-align: center;
  `,
  nftImg: css`
    width: 160px;
    padding: 24px 0 0 0;
  `,
  id: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 48px;
  `,
  cancelBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
}

export default styles
