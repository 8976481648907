import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css`
    display: flex;
   
  `,
  contentWrapper: css`
    padding: 16px;
    flex: 1;
  `,
  walletInfo: css`
    background-color: ${baseColors.imgBg};
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 0 12px;
  `,
  walletInfoItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 20px 0;
    border-top: 1px solid #2c395b;
    &:first-of-type {
      border: 0;
    }
  `,
  tokenIcon: css`
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
  `,
}

export default styles
