import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  transferBox: css`
    display: flex;
    align-items: center;
    background-color: ${baseColors.cardBg};
    padding: 20px;
    border-radius: 12px;
  `,
  transferWrapper: css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  `,
  transferItem: css`
    display: flex;
    align-items: center;
  `,
  transferItemIcon: css`
    min-width: 50px;
  `,
  transferItemLabel: css`
    font-size: 14px;
    color: ${baseColors.neutralColor7};
    min-width: 70px;
  `,
  divider: css`
    height: 1px;
    border-top: 1px solid #2C395B;
  `,
  transferSwitch: css`
    width: 50px;
    text-align: right;
  `,
  btnSwitch: css`
    background: none;
    border: none;
    cursor: pointer;
  `,
}

export default styles
