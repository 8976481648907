import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  modalWrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    max-height: 90vh;
    overflow: auto;
    width: 300px;
   
  `,
  modalTitle: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 24px;

  `,

  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  cancelBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  inputWrapper: css`
    position: relative;
  `,
  input: css`
    background: #25253D;
    color:#fff;
    border: 1px solid #6D83B4;
    border-radius: 12px;
    width: 100%;
    .MuiSelect-select{
      color:#fff;
      display: flex;
      align-items: center;
      .icon{
        max-width: 24px;
        margin-right: 10px;
      }
      
    }
    .MuiOutlinedInput-notchedOutline{
      border-color: transparent !important;
    }
  `,
  inputSuffix: css`
    position: absolute;
    right: 15px;
    top: 20px;
  `,
  slippageBox: css`
    display: flex;
    margin: 20px 0;
    gap: 10px;
  `,
  slippageItem: css`
    background-color: #45516e;
    border-radius: 10px;
    padding: 5px 15px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  `,
  slippageItemActive: css`
    background: linear-gradient(180deg, #FFBAA1 0%, #FD3F46 100%);
  `,
}

export default styles
