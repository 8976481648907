/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import { fetchTransactionHistory } from 'store/reducers/transaction'
import { useAppDispatch, useAppSelector } from 'store'
import TransactionHistory from './TransactionHistory'
import TransactionPending from './TransactionPending'

import styles from './Transaction.styles'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Transaction = () => {
  const dispatch = useAppDispatch()
  const accessToken = useAppSelector((state) => state.auth.token)

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchTransactionHistory({ filter: [] }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='transaction'
            css={styles.tabs}
            variant='fullWidth'
          >
            <Tab label='Transaction History' {...a11yProps(0)} />
            <Tab label='Pending' {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} css={styles.tabPanel}>
          <TransactionHistory />
        </TabPanel>
        <TabPanel value={value} index={1} css={styles.tabPanel}>
          <TransactionPending />
        </TabPanel>
      </Box>
    </div>
  )
}

export default Transaction
