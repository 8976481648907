import { css } from '@emotion/react'
const styles = {
  wrapper: css`
    padding: 16px;
    gap: 24px;
    display: flex;

    background: #171828;
    border-radius: 12px;
    margin-top: 24px;
  `,
  info: css`
    flex: 1;
    text-align: center;
    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-bottom: 8px;
      display: block;
    }
    p {
      letter-spacing: 0.4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
  properties: css`
    display: flex;
    padding: 24px 0;
  `,
  attributeWrap: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  `,
  attributeIcon: css`
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    border-radius: 12px;
  `,
  attributeTextIcon: css``,
  attributeText: css``,
}

export default styles
