import { SWAP, TOKEN_TYPE } from 'types/common'

export const getSwapDirection = (
  tokenFrom: any,
  tokenTo: any,
  swapExactToken: any,
) => {
  if (
    tokenFrom.type === TOKEN_TYPE.BNB &&
    tokenTo.type === TOKEN_TYPE.CALO &&
    swapExactToken.type === TOKEN_TYPE.BNB
  ) {
    // swap bnb to calo
    return SWAP.SWAP_EXACT_BNB_FOR_CALO
  } else if (
    tokenFrom.type === TOKEN_TYPE.BNB &&
    tokenTo.type === TOKEN_TYPE.FIT &&
    swapExactToken.type === TOKEN_TYPE.BNB
  ) {
    // swap bnb to fit
    return SWAP.SWAP_EXACT_BNB_FOR_FIT
  } else if (
    (tokenFrom.type === TOKEN_TYPE.CALO &&
      tokenTo.type === TOKEN_TYPE.BNB &&
      swapExactToken.type === TOKEN_TYPE.CALO) ||
    (tokenFrom.type === TOKEN_TYPE.BNB &&
      tokenTo.type === TOKEN_TYPE.CALO &&
      swapExactToken.type === TOKEN_TYPE.CALO)
  ) {
    // swap calo to bnb
    return SWAP.SWAP_EXACT_CALO_FOR_BNB
  } else if (
    (tokenFrom.type === TOKEN_TYPE.FIT &&
      tokenTo.type === TOKEN_TYPE.BNB &&
      swapExactToken.type === TOKEN_TYPE.FIT) ||
    (tokenFrom.type === TOKEN_TYPE.BNB &&
      tokenTo.type === TOKEN_TYPE.FIT &&
      swapExactToken.type === TOKEN_TYPE.FIT)
  ) {
    // swap fit to bnb
    return SWAP.SWAP_EXACT_FIT_FOR_BNB
  } else if (
    (tokenFrom.type === TOKEN_TYPE.CALO &&
      tokenTo.type === TOKEN_TYPE.FIT &&
      swapExactToken.type === TOKEN_TYPE.CALO) ||
    (tokenFrom.type === TOKEN_TYPE.FIT &&
      tokenTo.type === TOKEN_TYPE.CALO &&
      swapExactToken.type === TOKEN_TYPE.CALO)
  ) {
    // swap calo to fit
    return SWAP.SWAP_EXACT_CALO_FOR_FIT
  } else if (
    (tokenFrom.type === TOKEN_TYPE.FIT &&
      tokenTo.type === TOKEN_TYPE.CALO &&
      swapExactToken.type === TOKEN_TYPE.FIT) ||
    (tokenFrom.type === TOKEN_TYPE.CALO &&
      tokenTo.type === TOKEN_TYPE.FIT &&
      swapExactToken.type === TOKEN_TYPE.FIT)
  ) {
    // swap fit to calo
    return SWAP.SWAP_EXACT_FIT_FOR_CALO
  } else {
    return ''
  }
}
