/** @jsxImportSource @emotion/react */
import ProgressBar from 'components/progress-bar/ProgressBar'
import { baseColors } from 'theme'
import { IShoe } from 'types/nft'
import styles from './LifeTimeInfo.styles'
interface IProps {
  className?: string
  shoeDetail?: IShoe
}
const LifeTimeInfo = ({ className, shoeDetail }: IProps) => {
  return (
    <div className={className}>
      <div className='df jcb'>
        <div css={styles.labelText}>Lifetime</div>
        <div css={styles.valueText}>
          <span>{shoeDetail?.lifetime}</span>/1000
        </div>
      </div>
      <ProgressBar
        completeColor={baseColors.lifetime}
        value={shoeDetail?.lifetime || 0}
        maxValue={1000}
      />
    </div>
  )
}

export default LifeTimeInfo
