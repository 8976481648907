import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _map from 'lodash/map'
import { IMarketListItem } from 'types/market'
import {
  getMarketListApi,
  getMarketItemApi,
  buyMarketItemApi,
  sellItemApi,
  revokeItemApi,
} from 'api/market'
import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { NFT_TYPE } from 'types/common'
import { convertStructureMarketListItem } from 'store/middlewares/convert-structure/market'
export const TYPE = 'MARKET_LIST'

export const fetchMarketList = createCallApiAsyncAction(
  TYPE + '/fetchMarketList',
  getMarketListApi,
  (old) => {
    return {
      data: _map(old.data, (v) => convertStructureMarketListItem(v)),
    }
  },
)

export const fetchMarketItem = createCallApiAsyncAction(
  TYPE + '/fetchMarketItem',
  getMarketItemApi,
  convertStructureMarketListItem,
)

export const buyMarketItem = createCallApiAsyncAction(
  TYPE + '/buyMarketItem',
  buyMarketItemApi,
)

export const sellMarketItem = createCallApiAsyncAction(
  TYPE + '/sellMarketItem',
  sellItemApi,
)

export const revokeMarketItem = createCallApiAsyncAction(
  TYPE + '/revokeMarketItem',
  revokeItemApi,
)

interface MarketListState {
  shoeData: IMarketListItem[]
  shoeBoxData: IMarketListItem[]
  stoneData: IMarketListItem[]
  manaData: IMarketListItem[]

  hasMore: boolean

  shoeDetail: IMarketListItem | null
}

const initialState: MarketListState = {
  shoeData: [],
  shoeBoxData: [],
  stoneData: [],
  manaData: [],
  hasMore: false,

  shoeDetail: null,
}

const marketListSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setMarketShoeDetail(state, action: PayloadAction<IMarketListItem>) {
      state.shoeDetail = action.payload
    },
  },
  extraReducers: (builder) => {
    // fetchMarketList
    builder.addCase(fetchMarketList.fulfilled, (state, action) => {
      const { request, response } = action.payload

      if (response.apiStatus) {
        if (request.type === NFT_TYPE.SHOE) {
          if (request.offset) {
            state.shoeData.push(...(response?.data || []))
          } else {
            state.shoeData = response?.data || []
          }
        }

        if (request.type === NFT_TYPE.SHOEBOX) {
          if (request.offset) {
            state.shoeBoxData.push(...(response?.data || []))
          } else {
            state.shoeBoxData = response?.data || []
          }
        }

        if (request.type === NFT_TYPE.STONE) {
          if (request.offset) {
            state.stoneData.push(...(response?.data || []))
          } else {
            state.stoneData = response?.data || []
          }
        }

        if (request.type === NFT_TYPE.MANA) {
          if (request.offset) {
            state.manaData.push(...(response?.data || []))
          } else {
            state.manaData = response?.data || []
          }
        }
      } else {
        if (request.type === NFT_TYPE.SHOE) {
          state.shoeData = []
        }

        if (request.type === NFT_TYPE.SHOEBOX) {
          state.shoeBoxData = []
        }

        if (request.type === NFT_TYPE.STONE) {
          state.stoneData = []
        }

        if (request.type === NFT_TYPE.MANA) {
          state.manaData = []
        }
      }

      state.hasMore = (response?.data || []).length !== 0
    })
    builder.addCase(fetchMarketList.pending, () => {})

    // fetchMarketItem
    builder.addCase(fetchMarketItem.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.shoeDetail = response
      } else {
        state.shoeDetail = null
      }
    })
    builder.addCase(fetchMarketItem.pending, () => {})
  },
})

export const { setMarketShoeDetail } = marketListSlice.actions
export default marketListSlice.reducer
