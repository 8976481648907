import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'

const styles = {
  modalWrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    max-height: 90vh;
    overflow: auto;
    width: 300px;
    /* @media only screen and (min-width: ${mobileWidth}px) {
      width: 327px;
    } */
  `,
  modalTitle: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 24px;

  `,
  header: css`
    margin-bottom: 32px;
  `,
  title: css`
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
    color: rgb(255, 255, 255);
  `,
  btnWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    // justify-content: space-between;
    gap: 20px;
    width: calc(100% - 20px);
  `,
  btnConnect: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(29 29 49);
    border-radius: 15px;
    opacity: 1;
    cursor: pointer;
    flex: 0 0 50%;
    height: 130px;
    img{
      max-width: 100px;
    }
  `,
  textHelper: css`
    margin-top: 32px;
    font-weight: 500;
    line-height: 160%;
    text-align: center;
    color: rgb(147, 152, 154);
    font-size: 15px;
  `,

}

export default styles
