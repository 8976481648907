/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { showToast } from 'store/reducers/common'
import styles from './GlobalAlert.styles'
const GlobalAlert = () => {
  const toast = useAppSelector((state) => state.common.toast)
  const [show, setShow] = useState(false)
  const dispatch = useAppDispatch()
  const timer1 = useRef<NodeJS.Timeout | undefined>()
  const timer2 = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    if (toast?.title) {
      setShow(true)
      timer1.current = setTimeout(() => {
        setShow(false)
        timer2.current = setTimeout(() => {
          dispatch(showToast(null))
        }, 600)
      }, 3000)
    }

    return () => {
      clearInterval(timer1.current)
      clearInterval(timer2.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast])

  return (
    <div css={[styles.toastWrapper, show ? styles.visible : styles.hidden]}>
      <p css={styles.title}>{toast?.title}</p>

      {!!toast?.message && <p css={styles.message}>{toast?.message}</p>}
    </div>
  )
}

export default GlobalAlert
