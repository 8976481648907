import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  toastWrapper: css`
    padding: 16px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${baseColors.toastBg};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    z-index: 9999;
    min-width: 300px;
    text-align: center;
  `,
  visible: css`
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
  `,

  hidden: css`
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  `,
  title: css`
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    color: ${baseColors.neutralColor1};
  `,
  message: css`
    margin: 0;
    margin-top: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: ${baseColors.neutralColor6};
  `,
}

export default styles
