const animationJsonData = {
  v: '5.7.5',
  fr: 29.9700012207031,
  ip: 0,
  op: 30.0000012219251,
  w: 500,
  h: 500,
  nm: 'OpenBox',
  ddd: 0,
  assets: [
    {
      id: 'imgSeq_0',
      w: 500,
      h: 500,
      t: 'seq',
      p: require('./images/open_seq_0_0.png'),
      e: 0,
    },
    {
      id: 'imgSeq_1',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_1.png'),
      e: 0,
    },
    {
      id: 'imgSeq_2',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_2.png'),
      e: 0,
    },
    {
      id: 'imgSeq_3',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_3.png'),
      e: 0,
    },
    {
      id: 'imgSeq_4',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_4.png'),
      e: 0,
    },
    {
      id: 'imgSeq_5',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_5.png'),
      e: 0,
    },
    {
      id: 'imgSeq_6',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_6.png'),
      e: 0,
    },
    {
      id: 'imgSeq_7',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_7.png'),
      e: 0,
    },
    {
      id: 'imgSeq_8',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_8.png'),
      e: 0,
    },
    {
      id: 'imgSeq_9',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_9.png'),
      e: 0,
    },
    {
      id: 'imgSeq_10',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_10.png'),
      e: 0,
    },
    {
      id: 'imgSeq_11',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_11.png'),
      e: 0,
    },
    {
      id: 'imgSeq_12',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_12.png'),
      e: 0,
    },
    {
      id: 'imgSeq_13',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_13.png'),
      e: 0,
    },
    {
      id: 'imgSeq_14',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_14.png'),
      e: 0,
    },
    {
      id: 'imgSeq_15',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_15.png'),
      e: 0,
    },
    {
      id: 'imgSeq_16',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_16.png'),
      e: 0,
    },
    {
      id: 'imgSeq_17',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_17.png'),
      e: 0,
    },
    {
      id: 'imgSeq_18',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_18.png'),
      e: 0,
    },
    {
      id: 'imgSeq_19',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_19.png'),
      e: 0,
    },
    {
      id: 'imgSeq_20',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_20.png'),
      e: 0,
    },
    {
      id: 'imgSeq_21',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_21.png'),
      e: 0,
    },
    {
      id: 'imgSeq_22',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_22.png'),
      e: 0,
    },
    {
      id: 'imgSeq_23',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_23.png'),
      e: 0,
    },
    {
      id: 'imgSeq_24',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_24.png'),
      e: 0,
    },
    {
      id: 'imgSeq_25',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_25.png'),
      e: 0,
    },
    {
      id: 'imgSeq_26',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_26.png'),
      e: 0,
    },
    {
      id: 'imgSeq_27',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_27.png'),
      e: 0,
    },
    {
      id: 'imgSeq_28',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_28.png'),
      e: 0,
    },
    {
      id: 'imgSeq_29',
      w: 500,
      h: 500,
      t: 'seq',

      p: require('./images/open_seq_0_29.png'),
      e: 0,
    },
    {
      id: 'sequence_0',
      layers: [
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_0',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 0,
          st: 0,
          op: 1,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_1',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 1,
          st: 1,
          op: 2,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_2',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 2,
          st: 2,
          op: 3,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_3',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 3,
          st: 3,
          op: 4,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_4',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 4,
          st: 4,
          op: 5,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_5',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 5,
          st: 5,
          op: 6,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_6',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 6,
          st: 6,
          op: 7,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_7',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 7,
          st: 7,
          op: 8,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_8',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 8,
          st: 8,
          op: 9,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_9',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 9,
          st: 9,
          op: 10,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_10',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 10,
          st: 10,
          op: 11,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_11',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 11,
          st: 11,
          op: 12,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_12',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 12,
          st: 12,
          op: 13,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_13',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 13,
          st: 13,
          op: 14,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_14',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 14,
          st: 14,
          op: 15,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_15',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 15,
          st: 15,
          op: 16,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_16',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 16,
          st: 16,
          op: 17,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_17',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 17,
          st: 17,
          op: 18,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_18',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 18,
          st: 18,
          op: 19,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_19',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 19,
          st: 19,
          op: 20,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_20',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 20,
          st: 20,
          op: 21,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_21',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 21,
          st: 21,
          op: 22,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_22',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 22,
          st: 22,
          op: 23,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_23',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 23,
          st: 23,
          op: 24,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_24',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 24,
          st: 24,
          op: 25,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_25',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 25,
          st: 25,
          op: 26,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_26',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 26,
          st: 26,
          op: 27,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_27',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 27,
          st: 27,
          op: 28,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_28',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 28,
          st: 28,
          op: 29,
          sr: 1,
          bm: 0,
        },
        {
          ty: 2,
          ind: 3,
          sc: '#00ffff',
          refId: 'imgSeq_29',
          ks: {
            p: { a: 0, k: [0, 0] },
            a: { a: 0, k: [0, 0] },
            s: { a: 0, k: [100, 100] },
            r: { a: 0, k: [0] },
            o: { a: 0, k: [100] },
          },
          ip: 29,
          st: 29,
          op: 31,
          sr: 1,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'OpenBox',
      refId: 'sequence_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 250, 0], ix: 2, l: 2 },
        a: { a: 0, k: [250, 250, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 500,
      h: 500,
      ip: 0,
      op: 29.9700012207031,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default animationJsonData
