import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  menuWrapper: css`
    width: 100%;
    height: 100%;
    max-width: 460px;
    margin: 0 auto;
    background-color: ${baseColors.cardBg};
    padding: 16px;
    color: ${baseColors.neutralColor1};
    position: relative;
    border-radius: 16px 16px 0px 0px;
    box-sizing: border-box;
  `,
  tokenItem: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #2c395b;
    }
  `,
  tokenIcon: css`
    width: 42px;
    height: 42px;
    padding-right: 24px;
  `,
  tokenName: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  `,
}

export default styles
