import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'

const styles = {
  modalWrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    max-height: 90vh;
    overflow: auto;
    width: 300px;
    @media only screen and (min-width: ${mobileWidth}px) {
      width: 327px;
    }
  `,
  modalTitle: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 24px;

  `,
  bgWrapper: css`
    width: calc(95vw - 48px);
    height: calc((95vw - 48px) * 0.6615);

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
      height: calc(500px * 0.6615);
    }
  `,
  bgWrapperContent: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  `,

  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  cancelBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  boxDirection: css`
    background: ${baseColors.appBg};
    border-radius: 12px;
    padding: 10px 20px;
  `,
  directionItem: css`
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  `,
  directionFrom: css`
    font-size: 14px;
    color: ${baseColors.neutralColor6};
  `,
  directionTo: css`
    color: #fff;
  `,
  transferToken: css`
    margin: 20px 0 0;
  `,
  transferItem: css`
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  `,
  transferItemLabel: css`
    font-size: 14px;
    color: ${baseColors.neutralColor6};
    min-width: 140px;
  `,
  transferItemValue: css`
    color: #fff;
  `,
  otpSendTo: css`
    text-align: center;
  `,
  formControl: css`
    margin: 20px 0 20px 0;
    position: relative;
    .Mui-disabled.MuiOutlinedInput-root{
      background: #484861;
      border-radius: 12px;
    }
  `,
  inputWrapper: css`
    background: ${baseColors.appBg};
    color:#fff;
    border: none;
    border-radius: 12px;
    .MuiSelect-select{
      color:#fff;
      display: flex;
      align-items: center;
      .icon{
        max-width: 24px;
        margin-right: 10px;
      }
      
    }
    .MuiOutlinedInput-notchedOutline{
      border-color: transparent !important;
    }
  `,
  sendOtpBtn: css`
    position: absolute;
    right: 10px;
    top: 10px;
  `,
  timeCountdown: css`
    top: 17px;
  `,
}

export default styles
